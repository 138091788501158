import React from 'react';
import CallToAction from '../../../molecules/CallToAction/CallToAction';
import Section from '../../../molecules/Section/Section';
import { colors } from '../../../styles/atoms/colors';
import BasicCard from '../../Cards/BasicCard';
import ResourceCard from '../../Cards/ResourcesCard';
import CardDeckHeader from '../components/CardDeckHeader';
import { Grid, Wrapper } from '../styles/DefaultCardDeck.styled';

const CardDeckDefault = ({ component, wide }) => {
  const { id, cards, background, cta } = component;

  return (
    <Section id={id} bgColor={background || colors.base.white}>
      <Wrapper>
        <CardDeckHeader {...component} />
        <Grid wide={wide} numOfCards={cards?.length}>
          {cards &&
            cards.map(card => {
              if (
                card.blockHeading ||
                card.inlineHeading ||
                (card.background && card.background !== 'default')
              ) {
                return <BasicCard key={card.id} card={card} wide={wide} />;
              }

              return (
                // Style regular cards like resource cards
                <ResourceCard
                  image={card.image}
                  key={card.id}
                  link={card.link?.url}
                  subhead={card.subhead}
                  title={card.heading}
                  wide={wide}
                />
              );
            })}
        </Grid>
        {cta ? (
          <div className="cta-container">
            <CallToAction
              variant={cta.variant}
              size={cta.size}
              value={cta.label}
              link={cta.url}
              icon={cta.icon}
              reverse={cta.reverse}
            />
          </div>
        ) : (
          ''
        )}
      </Wrapper>
    </Section>
  );
};

export default CardDeckDefault;
